<template>
    <div class="proContent4">
        <!-- 右侧导航 -->
        <div class="taLeft">
            <ul>
                <li class="tlLi">
                    <a href="#ac1" :class="{ tlTSize: tts === 1 }">一物一码导航</a>
                    <div class="tlActive"></div>
                </li>
                <li class="tlLi">
                    <a href="#ac2" :class="{ tlTSize: tts === 2 }">防伪营销</a>
                    <div class="tlActive2"></div>
                </li>
                <li class="tlLi">
                    <a href="#ac3" :class="{ tlTSize: tts === 3 }">防窜物流</a>
                    <div class="tlActive2"></div>
                </li>
                <li class="tlLi">
                    <a href="#ac4" :class="{ tlTSize: tts === 4 }">万能溯源</a>
                    <div class="tlActive2"></div>
                </li>
                <li class="tlLi">
                    <a href="#ac5" :class="{ tlTSize: tts === 5 }">营销应用</a>
                    <div class="tlActive2"></div>
                </li>
                <li class="tlLi">
                    <a href="#ac6" :class="{ tlTSize: tts === 6 }">积分商城</a>
                    <div class="tlActive2"></div>
                </li>
                <li class="tlLi">
                    <a href="#ac7" :class="{ tlTSize: tts === 7 }">微商控价</a>
                    <div class="tlActive2"></div>
                </li>
                <li class="tlLi">
                    <a href="#ac8" :class="{ tlTSize: tts === 8 }">客户CRM</a>
                    <div class="tlActive2"></div>
                </li>
                <li class="tlLi">
                    <a href="#ac9" :class="{ tlTSize: tts === 9 }">微商城</a>
                    <div class="tlActive2"></div>
                </li>
                <li class="tlLi">
                    <a href="#ac10" :class="{ tlTSize: tts === 10 }">品牌推广</a>
                    <div class="tlActive2"></div>
                </li>
                <li class="tlLi">
                    <a href="#ac11" :class="{ tlTSize: tts === 11 }">多门店、社群营销</a>
                    <div class="tlActive2"></div>
                </li>
            </ul>
            <div class="tlBottom">
                <img src="https://iv.vu818.com/img/menu_2.png" alt="" class="tlb" />
                <div class="tlb2" :class="tlbA ? 'tlbAn' : ''"></div>
                <img src="https://iv.vu818.com/img/menu_1.png" alt="" class="tlb" />
                <div class="tlb3" :class="tlbA2 ? 'tlbAn' : ''"></div>
            </div>
        </div>
        <div class="p4Box1" id="ac1">
            <div class="p1Box">
                <div class="plbLeft">
                    <p class="pl1 wow animate__animated animate__slideInLeft">一物一码营销</p>
                    <p class="pl2 wow animate__animated animate__slideInLeft" data-wow-delay="0.1s">
                        新零售时代，助力传统企业实现成功转型 <br />
                        加强品牌与消费者的紧密联系，提升品牌信任度
                    </p>
                    <div class="pl3 wow animate__animated animate__slideInLeft" data-wow-delay="0.2s">
                        <ul>
                            <li
                                class="p3Li"
                                v-for="(item, i) in [
                                    { left: 8 },
                                    { left: -37 },
                                    { left: -90 },
                                    { left: -142 },
                                    { left: -196 },
                                    { left: -246 },
                                    { left: -302 },
                                ]"
                                :key="i"
                            >
                                <div class="p3l1">
                                    <!-- <img src="https://iv.vu818.com/img/wm1-1.png" alt="" /> -->
                                    <img
                                        :style="{ top: '-987px', left: `${item.left}px` }"
                                        src="https://iv.vu818.com/img/vu918.png"
                                        alt=""
                                    />
                                </div>
                                <p class="p3l2">防伪营销</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="plbRight">
                    <img src="https://iv.vu818.com/img/wm-1-1.png" alt="" class="pr1 wow animate__animated animate__slideInUp" />
                    <img
                        src="https://iv.vu818.com/img/wm-1-2.png"
                        alt=""
                        class="pr2 wow animate__animated animate__slideInUp"
                        data-wow-delay="0.1s"
                    />
                </div>
            </div>
        </div>
        <div class="p4Box2" id="ac2">
            <div class="p2Box">
                <div class="p2bLeft">
                    <img
                        src="https://iv.vu818.com/img/wm-2-1.png"
                        alt=""
                        class="p2Img1 wow animate__animated animate__slideInDown"
                    />
                    <img
                        src="https://iv.vu818.com/img/wm-2-2.png"
                        alt=""
                        class="p2Img2 wow animate__animated animate__slideInUp"
                    />
                    <img
                        src="https://iv.vu818.com/img/wm-2-3.png"
                        alt=""
                        class="p2Img3 wow animate__animated animate__fadeInUp"
                    />
                </div>
                <div class="p2bRight">
                    <p class="p2r1 wow animate__animated animate__slideInRight">防伪营销</p>
                    <p class="p2r2 wow animate__animated animate__slideInRight" data-wow-delay="0.1s">
                        智能营销是威有智慧中的一大核心产品。
                        主要以一物一码为基础，运用三重空码赋值技术对用户接触产品的高频场景进行赋码改造，
                        实现二维码防伪查询、二维码防窜预警、终端消费者营销、导购员促销、渠道动销等功能。
                        通过二维码，与终端消费者形成线上互动，
                        同时收集消费者精准信息，最后通过数据分析，为品牌商提供市场决策参考。
                    </p>
                    <div class="p2r3 wow animate__animated animate__slideInRight" data-wow-delay="0.2s">
                        <p class="p3p">功能</p>
                        <ul>
                            <li
                                class="p3Li"
                                v-for="(item, i) in [
                                    { left: -358 },
                                    { left: -411 },
                                    { left: -462 },
                                    { left: -512 },
                                    { left: -563 },
                                    { left: -614 },
                                    { left: -667 },
                                    { left: -718 },
                                    { left: -768 },
                                ]"
                                :key="i"
                            >
                                <div class="p3l1" style="height: 41px !important">
                                    <img
                                        :style="{ top: '-987px', left: `${item.left}px` }"
                                        src="https://iv.vu818.com/img/vu918.png"
                                        alt=""
                                    />
                                </div>
                                <p class="p3l2">精准营销</p>
                            </li>
                        </ul>
                    </div>
                    <div class="p2r4" @click="getDetail">查看更多</div>
                </div>
            </div>
        </div>
        <div class="p4Box3" id="ac3">
            <div class="p3Box">
                <div class="p3bLeft">
                    <p class="p3r1 wow animate__animated animate__slideInLeft">防窜物流</p>
                    <p class="p3r2 wow animate__animated animate__slideInLeft" data-wow-delay="0.1s">
                        通过一物一码将防窜和防伪营销溯源等功能合为一体，让产品在快速流通售卖的同时，通过电脑、PDA或APP进行企业经销商管理、产品物流管理、窜货信息收集、市场稽查管理等功能模块管理；从而实现自动进行窜货预警，全面防范杜绝窜货行为，营造一个健康良好的市场销售氛围，提高品牌形象，稳固市场，同时还可以结合消费者扫码数据做分析，实现大数据防窜货。
                    </p>
                    <div class="p3r3 wow animate__animated animate__slideInLeft" data-wow-delay="0.2s">
                        <p class="p3p">功能</p>
                        <ul>
                            <li
                                class="p3Li"
                                v-for="(item, i) in [
                                    { left: -820 },
                                    { left: -872 },
                                    { left: -923 },
                                    { left: -976 },
                                    { left: -1025 },
                                    { left: 2, top: -1044 },
                                    { left: -45, top: -1044 },
                                    { left: -99, top: -1044 },
                                    { left: -151, top: -1044 },
                                ]"
                                :key="i"
                            >
                                <div class="p3l1">
                                    <img
                                        :style="{ top: `${item.top ? item.top : -987}px`, left: `${item.left}px` }"
                                        src="https://iv.vu818.com/img/vu918.png"
                                        alt=""
                                    />
                                </div>
                                <p class="p3l2">发货管理</p>
                            </li>
                        </ul>
                    </div>
                    <div class="p3r4" @click="getDetail">查看更多</div>
                </div>
                <div class="p3bRight">
                    <img
                        src="https://iv.vu818.com/img/wm-3-1.png"
                        alt=""
                        class="p3Img1 wow animate__animated animate__slideInDown"
                    />
                    <img
                        src="https://iv.vu818.com/img/wm-3-2.png"
                        alt=""
                        class="p3Img2 wow animate__animated animate__slideInRight"
                    />
                    <img
                        src="https://iv.vu818.com/img/wm-3-3.png"
                        alt=""
                        class="p3Img3 wow animate__animated animate__slideInLeft"
                    />
                    <img
                        src="https://iv.vu818.com/img/wm-3-4.png"
                        alt=""
                        class="p3Img4 wow animate__animated animate__fadeInUp"
                    />
                </div>
            </div>
        </div>
        <div class="p4Box4" id="ac4">
            <div class="p2Box">
                <div class="p2bLeft">
                    <img
                        src="https://iv.vu818.com/img/wm-4-1.png"
                        alt=""
                        class="p2Img1 wow animate__animated animate__slideInDown"
                    />
                    <img
                        src="https://iv.vu818.com/img/wm-4-2.png"
                        alt=""
                        class="p2Img2 wow animate__animated animate__slideInUp"
                    />
                    <img
                        src="https://iv.vu818.com/img/wm-4-3.png"
                        alt=""
                        class="p2Img3 wow animate__animated animate__fadeInUp"
                    />
                </div>
                <div class="p2bRight">
                    <p class="p2r1 wow animate__animated animate__slideInRight">万能溯源</p>
                    <p class="p2r2 wow animate__animated animate__slideInRight" data-wow-delay="0.1s">
                        通过二维码的方式对单个产品赋予‘身份证’追溯码，实现“一物一码”，对产品的生产、仓储、分销、物流运输、市场巡检及消费者等环节进行数据采集跟踪，实现产品生产环节、销售环节、流通环节、服务环节的全生命周期信息管理。独创“万能引擎”技术，通过对追溯流程的高度自定义，适应各行各业以及品类繁多的产品追溯需求。
                    </p>
                    <div class="p2r3 wow animate__animated animate__slideInRight" data-wow-delay="0.2s">
                        <p class="p3p">功能</p>
                        <ul>
                            <li
                                class="p3Li"
                                v-for="(item, i) in [
                                    { left: -202 },
                                    { left: -252 },
                                    { left: -305 },
                                    { left: -361 },
                                    { left: -414 },
                                    { left: -463 },
                                    { left: -513 },
                                    { left: -571 },
                                    { left: -638 },
                                ]"
                                :key="i"
                            >
                                <div class="p3l1">
                                    <img
                                        :style="{ top: `-1044px`, left: `${item.left}px` }"
                                        src="https://iv.vu818.com/img/vu918.png"
                                        alt=""
                                    />
                                </div>
                                <p class="p3l2">万能引擎</p>
                            </li>
                        </ul>
                    </div>
                    <div class="p2r4" @click="getDetail">查看更多</div>
                </div>
            </div>
        </div>
        <div class="p4Box5" id="ac5">
            <div class="p3Box">
                <div class="p3bLeft">
                    <p class="p3r1 wow animate__animated animate__slideInLeft">营销应用</p>
                    <p class="p3r2 wow animate__animated animate__slideInLeft" data-wow-delay="0.1s">
                        目前包括：签到、投票评选、砍价、大转盘抽奖、刮刮卡抽奖、梦想助力、问卷调查、口令红包、微现场、摇一摇送红包共10款应用，可以适合任何规模的企业，是所有品牌和机构社会化营销的最佳解决方案。
                    </p>
                    <div class="p3r3 wow animate__animated animate__slideInLeft" data-wow-delay="0.2s">
                        <p class="p3p">功能</p>
                        <ul>
                            <li
                                class="p3Li"
                                v-for="(item, i) in [
                                    { left: -704 },
                                    { left: -760 },
                                    { left: -815 },
                                    { left: -873 },
                                    { left: -927 },
                                    { left: -983 },
                                    { left: -1034 },
                                    { left: 10, top: -1102 },
                                    { left: -35, top: -1102 },
                                ]"
                                :key="i"
                            >
                                <div class="p3l1">
                                    <img
                                        :style="{ top: `${item.top ? item.top : '-1044'}px`, left: `${item.left}px` }"
                                        src="https://iv.vu818.com/img/vu918.png"
                                        alt=""
                                    />
                                </div>
                                <p class="p3l2">签到</p>
                            </li>
                        </ul>
                    </div>
                    <div class="p3r4" @click="getDetail">查看更多</div>
                </div>
                <div class="p3bRight">
                    <img
                        src="https://iv.vu818.com/img/wm-5-1.png"
                        alt=""
                        class="p3Img1 wow animate__animated animate__slideInDown"
                    />
                    <img src="https://iv.vu818.com/img/wm-5-2.png" alt="" class="p3Img2 wow animate__animated animate__flipInX" />
                    <img
                        src="https://iv.vu818.com/img/wm-5-3.png"
                        alt=""
                        class="p3Img3 wow animate__animated animate__fadeInUp"
                    />
                </div>
            </div>
        </div>
        <div class="p4Box6" id="ac6">
            <div class="p2Box">
                <div class="p2bLeft">
                    <img
                        src="https://iv.vu818.com/img/wm-6-1.png"
                        alt=""
                        class="p2Img1 wow animate__animated animate__slideInDown"
                    />
                    <img
                        src="https://iv.vu818.com/img/wm-6-2.png"
                        alt=""
                        class="p2Img2 wow animate__animated animate__slideInUp"
                    />
                    <img
                        src="https://iv.vu818.com/img/wm-6-3.png"
                        alt=""
                        class="p2Img3 wow animate__animated animate__fadeInUp"
                    />
                </div>
                <div class="p2bRight">
                    <p class="p2r1 wow animate__animated animate__slideInRight">积分商城</p>
                    <p class="p2r2 wow animate__animated animate__slideInRight" data-wow-delay="0.1s">
                        提升用户活跃度、忠诚度和转化率的利器。 <br />
                        积分商城是用户积分的重要消耗出口，用户可以使用从其它渠道获得的积分兑换相关商品（实物礼品、虚拟礼品、联盟卡券、流量话费、购物券、微信红包等），通过积分商城做营销是促活粉丝、提升销量转化最有效的运营模式之一。
                    </p>
                    <div class="p2r3 wow animate__animated animate__slideInRight" data-wow-delay="0.1s">
                        <p class="p3p">功能</p>
                        <ul>
                            <li
                                class="p3Li"
                                v-for="(item, i) in [{ left: -88 }, { left: -140 }, { left: -194 }, { left: -247 }]"
                                :key="i"
                            >
                                <div class="p3l1">
                                    <img
                                        :style="{ top: `-1102px`, left: `${item.left}px` }"
                                        src="https://iv.vu818.com/img/vu918.png"
                                        alt=""
                                    />
                                </div>
                                <p class="p3l2">礼品类型丰富</p>
                            </li>
                        </ul>
                    </div>
                    <div class="p2r4" @click="getDetail">查看更多</div>
                </div>
            </div>
        </div>
        <div class="p4Box7" id="ac7">
            <div class="p3Box">
                <div class="p3bLeft">
                    <p class="p3r1 wow animate__animated animate__slideInLeft">微商控价</p>
                    <p class="p3r2 wow animate__animated animate__slideInLeft" data-wow-delay="0.1s">
                        智慧微商打破传统微商的经营管理模式，通过一物一码将防伪营销与管理合为一体，在大数据时代，为微商企业构建一个高端的营销管理服务体系，助企业更好的管人、管货、管钱。
                    </p>
                    <div class="p3r3 wow animate__animated animate__slideInLeft" data-wow-delay="0.2s">
                        <p class="p3p">功能</p>
                        <ul>
                            <li
                                class="p3Li"
                                v-for="(item, i) in [{ left: -300 }, { left: -356 }, { left: -409 }, { left: -461 }]"
                                :key="i"
                            >
                                <div class="p3l1">
                                    <img
                                        :style="{ top: `-1102px`, left: `${item.left}px` }"
                                        src="https://iv.vu818.com/img/vu918.png"
                                        alt=""
                                    />
                                </div>
                                <p class="p3l2">功能一体化</p>
                            </li>
                        </ul>
                    </div>
                    <div class="p3r4" @click="getDetail">查看更多</div>
                </div>
                <div class="p3bRight">
                    <img
                        src="https://iv.vu818.com/img/wm-7-1.png"
                        alt=""
                        class="p3Img1 wow animate__animated animate__slideInDown"
                    />
                    <img src="https://iv.vu818.com/img/wm-7-2.png" alt="" class="p3Img2 wow animate__animated animate__flipInY" />
                    <img
                        src="https://iv.vu818.com/img/wm-7-3.png"
                        alt=""
                        class="p3Img3 wow animate__animated animate__slideInUp"
                    />
                    <img
                        src="https://iv.vu818.com/img/wm-7-4.png"
                        alt=""
                        class="p3Img4 wow animate__animated animate__fadeInUp"
                    />
                </div>
            </div>
        </div>
        <div class="p4Box8" id="ac8">
            <div class="p2Box">
                <div class="p2bLeft">
                    <img
                        src="https://iv.vu818.com/img/wm-8-1.png"
                        alt=""
                        class="p2Img1 wow animate__animated animate__slideInUp"
                    />
                    <img
                        src="https://iv.vu818.com/img/wm-8-2.png"
                        alt=""
                        class="p2Img2 wow animate__animated animate__fadeInUp"
                    />
                </div>
                <div class="p2bRight">
                    <p class="p2r1 wow animate__animated animate__slideInRight">客户CRM</p>
                    <p class="p2r2 wow animate__animated animate__slideInRight" data-wow-delay="0.1s">
                        以C端消费者数据为中心，通过连接各种社交场景，结合数据分析，帮助企业有效增加用户流量，获利，提高收入及客户满意度的管理体系。客户CRM更强调消费者的参与和双边互动；消费者不再以单纯的物品（服务）的消费者或产权拥有者静态存在；更多是以品牌的关注者、聆听者、建议者、共同创造者存在；CRM管理可以让用户更加拥有归属感、趣味感和成就感；互动的双边关系，让消费者的需求和想法同品牌的定位的发展紧密结合；品牌和消费者真正融为一体。
                    </p>
                    <div class="p2r3 wow animate__animated animate__slideInRight" data-wow-delay="0.1s">
                        <p class="p3p">功能</p>
                        <ul>
                            <li
                                class="p3Li"
                                v-for="(item, i) in [{ left: -512 }, { left: -562 }, { left: -615 }, { left: -666 }]"
                                :key="i"
                            >
                                <div class="p3l1">
                                    <img
                                        :style="{ top: `-1102px`, left: `${item.left}px` }"
                                        src="https://iv.vu818.com/img/vu918.png"
                                        alt=""
                                    />
                                </div>
                                <p class="p3l2">客户管理</p>
                            </li>
                        </ul>
                    </div>
                    <div class="p2r4" @click="getDetail">查看更多</div>
                </div>
            </div>
        </div>
        <div class="p4Box9" id="ac9">
            <div class="p3Box">
                <div class="p3bLeft">
                    <p class="p3r1 wow animate__animated animate__slideInLeft">微商城</p>
                    <p class="p3r2 wow animate__animated animate__slideInLeft" data-wow-delay="0.1s">
                        通过公众账号的信息共享与传播，增加客户粘度，结合营销应用、智能营销、客户CRM、积分商城、微官网、渠道分销等，实现线上线下互通，去中心化流量聚合，客户粉丝沉淀，拥有自己的大数据引擎，助力企业快速步入移动电商时代！
                    </p>
                    <div class="p3r3 wow animate__animated animate__slideInLeft" data-wow-delay="0.2s">
                        <p class="p3p">功能</p>
                        <ul>
                            <li
                                class="p3Li"
                                v-for="(item, i) in [{ left: -716 }, { left: -766 }, { left: -819 }, { left: -871 }]"
                                :key="i"
                            >
                                <div class="p3l1">
                                    <img
                                        :style="{ top: `-1102px`, left: `${item.left}px` }"
                                        src="https://iv.vu818.com/img/vu918.png"
                                        alt=""
                                    />
                                </div>
                                <p class="p3l2">商品管理</p>
                            </li>
                        </ul>
                    </div>
                    <div class="p3r4" @click="getDetail">查看更多</div>
                </div>
                <div class="p3bRight">
                    <img
                        src="https://iv.vu818.com/img/wm-9-1.png"
                        alt=""
                        class="p3Img1 wow animate__animated animate__slideInDown"
                    />
                    <img
                        src="https://iv.vu818.com/img/wm-9-2.png"
                        alt=""
                        class="p3Img2 wow animate__animated animate__slideInUp"
                        data-wow-delay="0.2s"
                    />
                    <img
                        src="https://iv.vu818.com/img/wm-9-3.png"
                        alt=""
                        class="p3Img3 wow animate__animated animate__fadeInUp"
                    />
                </div>
            </div>
        </div>
        <div class="p4Box10" id="ac10">
            <div class="p2Box">
                <div class="p2bLeft">
                    <img
                        src="https://iv.vu818.com/img/wm-10-1.png"
                        alt=""
                        class="p2Img1 wow animate__animated animate__slideInDown"
                    />
                    <img
                        src="https://iv.vu818.com/img/wm-10-2.png"
                        alt=""
                        class="p2Img2 wow animate__animated animate__flipInY"
                    />
                    <img
                        src="https://iv.vu818.com/img/wm-10-3.png"
                        alt=""
                        class="p2Img3 wow animate__animated animate__fadeInUp"
                    />
                </div>
                <div class="p2bRight">
                    <p class="p2r1 wow animate__animated animate__slideInRight">品牌推广</p>
                    <p class="p2r2 wow animate__animated animate__slideInRight" data-wow-delay="0.1s">
                        帮助品牌商塑造自身及产品品牌形象，功能模块包含微官网、宣传画册、产品码、名片码。品牌商在微信公众号做营销活动，必须先进行品牌宣传包装，品牌推广系统是移动互联网时代的企业应用与商业服务平台，创新性地结合了移动互联网技术与企业信息化建设，实现了企业品牌展现、互动营销、商业交易与服务功能。
                    </p>
                    <div class="p2r3 wow animate__animated animate__slideInRight" data-wow-delay="0.1s">
                        <p class="p3p">功能</p>
                        <ul>
                            <li
                                class="p3Li"
                                v-for="(item, i) in [{ left: -924 }, { left: -976 }, { left: -1029 }, { left: 0, top: -1156 }]"
                                :key="i"
                            >
                                <div class="p3l1">
                                    <img
                                        :style="{ top: `${item.top ? item.top : '-1102'}px`, left: `${item.left}px` }"
                                        src="https://iv.vu818.com/img/vu918.png"
                                        alt=""
                                    />
                                </div>
                                <p class="p3l2">微官网</p>
                            </li>
                        </ul>
                    </div>
                    <div class="p2r4" @click="getDetail">查看更多</div>
                </div>
            </div>
        </div>
        <div class="p4Box11" id="ac11">
            <div class="p3Box">
                <div class="p3bLeft">
                    <p class="p3r1 wow animate__animated animate__slideInLeft">多门店、社群营销</p>
                    <p class="p3r2 wow animate__animated animate__slideInLeft" data-wow-delay="0.1s">
                        门店，新零售O2O营销管理系统。 <br />
                        多门店是基于门店的延伸应用服务，主要包含门店商城、自助下单、推广活动（粉丝推广有礼＋会员卡推广有礼）、佣金返利等功能，实现商户中心化支付、终端数据采集、门店与品牌商线上线下双向引流。
                    </p>
                    <div class="p3r3 wow animate__animated animate__slideInLeft" data-wow-delay="0.2s">
                        <p class="p3p">功能</p>
                        <ul>
                            <!-- <li class="p3Li" v-for="item in 4" :key="item">
                                <div class="p3l1">
                                    <img src="https://iv.vu818.com/img/vu918.png" alt="" />
                                </div>
                                <p class="p3l2">门店商城</p>
                            </li> -->
                            <li
                                class="p3Li"
                                v-for="(item, i) in [{ left: -48 }, { left: -101 }, { left: -152 }, { left: -204 }]"
                                :key="i"
                            >
                                <div class="p3l1">
                                    <img
                                        :style="{ top: `-1156px`, left: `${item.left}px` }"
                                        src="https://iv.vu818.com/img/vu918.png"
                                        alt=""
                                    />
                                </div>
                                <p class="p3l2">门店商城</p>
                            </li>
                        </ul>
                    </div>
                    <div class="p3r4" @click="getDetail">查看更多</div>
                </div>
                <div class="p3bRight">
                    <img
                        src="https://iv.vu818.com/img/wm-11-1.png"
                        alt=""
                        class="p3Img1 wow animate__animated animate__rotateIn"
                    />
                    <img
                        src="https://iv.vu818.com/img/wm-11-2.png"
                        alt=""
                        class="p3Img2 wow animate__animated animate__zoomInUp"
                    />
                    <img
                        src="https://iv.vu818.com/img/wm-11-3.png"
                        alt=""
                        class="p3Img3 wow animate__animated animate__fadeInUp"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ProContent4",
    data() {
        return {
            tlbA: false,
            tlbA2: false,
            // 滚动前，滚动条距文档顶部的距离
            oldScrollTop: 0,
            tts: 1,
        };
    },
    mounted() {
        window.addEventListener("scroll", this.scrollHandle); //绑定页面滚动事件
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: "ease-in-sine",
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: "wow",
            animateClass: "animate__animated",
            offset: 0,
            mobile: true,
            live: true,
        });
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.scrollHandle);
    },
    methods: {
        scrollHandle(e) {
            let top = e.srcElement.scrollingElement.scrollTop; // 获取页面滚动高度
            // 滚动条距文档顶部的距离
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            // 滚动条滚动的距离
            let scrollStep = scrollTop - this.oldScrollTop;
            // 更新——滚动前，滚动条距文档顶部的距离
            this.oldScrollTop = scrollTop;
            let tla = document.querySelector(".tlActive");
            let tla2 = document.querySelectorAll(".tlActive2");
            if (scrollStep < 0) {
                this.tlbA = true;
                setTimeout(() => {
                    this.tlbA = false;
                }, 300);
            } else {
                this.tlbA2 = true;
                setTimeout(() => {
                    this.tlbA2 = false;
                }, 300);
            }
            if (top < 700) {
                tla.style.opacity = 1;
                tla2[0].style.opacity = 0;
                this.tts = 1;
            } else if (top <= 1300) {
                tla.style.opacity = 0;
                tla2[0].style.opacity = 1;
                tla2[1].style.opacity = 0;
                this.tts = 2;
            } else if (top <= 2000) {
                tla2[0].style.opacity = 0;
                tla2[1].style.opacity = 1;
                tla2[2].style.opacity = 0;
                this.tts = 3;
            } else if (top <= 2700) {
                tla2[1].style.opacity = 0;
                tla2[2].style.opacity = 1;
                tla2[3].style.opacity = 0;
                this.tts = 4;
            } else if (top <= 3400) {
                tla2[2].style.opacity = 0;
                tla2[3].style.opacity = 1;
                tla2[4].style.opacity = 0;
                this.tts = 5;
            } else if (top <= 4100) {
                tla2[3].style.opacity = 0;
                tla2[4].style.opacity = 1;
                tla2[5].style.opacity = 0;
                this.tts = 6;
            } else if (top <= 4800) {
                tla2[4].style.opacity = 0;
                tla2[5].style.opacity = 1;
                tla2[6].style.opacity = 0;
                this.tts = 7;
            } else if (top <= 5500) {
                tla2[5].style.opacity = 0;
                tla2[6].style.opacity = 1;
                tla2[7].style.opacity = 0;
                this.tts = 8;
            } else if (top <= 6200) {
                tla2[6].style.opacity = 0;
                tla2[7].style.opacity = 1;
                tla2[8].style.opacity = 0;
                this.tts = 9;
            } else if (top < 6900) {
                tla2[7].style.opacity = 0;
                tla2[8].style.opacity = 1;
                tla2[9].style.opacity = 0;
                this.tts = 10;
            } else if (top < 7600) {
                tla2[8].style.opacity = 0;
                tla2[9].style.opacity = 1;
                this.tts = 11;
            }
        },
        getDetail() {
            this.$router.push("/proDetail");
        },
    },
};
</script>

<style lang="less">
.p3l1 {
    overflow: hidden;
    position: relative;
    width: 46px !important;
    height: 38px !important;
    margin: 0 auto;
    // background-color: red;
    & > img {
        width: auto !important;
        height: auto !important;
        position: absolute;
    }
}
.proContent4 {
    width: 100%;
    margin-top: 75px;
    position: relative;
    text-align: left;
    // 右侧导航
    .taLeft {
        width: 120px;
        height: 404px;
        position: fixed;
        right: 5%;
        top: 200px;
        z-index: 9;
        ul {
            width: 100%;
            height: 90%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            border-right: 1px dashed white;
            .tlLi {
                width: 100%;
                height: 20px;
                line-height: 20px;
                font-size: 12px;
                position: relative;
                text-align: center;
                a {
                    color: white;
                }
                .tlActive,
                .tlActive2 {
                    width: 15px;
                    height: 15px;
                    border-radius: 50%;
                    background: #7781f1;
                    transition: 0.5s;
                    position: absolute;
                    right: -8px;
                    top: 4px;
                    transition: 0.8s;
                }
                .tlActive2 {
                    background-color: #7781f1;
                    opacity: 0;
                }
            }
            .tlTSize {
                font-size: 17px;
                font-weight: 900;
                color: #7781f1 !important;
            }
        }
        .tlBottom {
            width: 100%;
            height: 10%;
            position: relative;
            .tlb {
                margin-left: 15px;
                margin-top: 10px;
            }
            .tlb2 {
                width: 10px;
                height: 10px;
                position: absolute;
                top: 25%;
                left: 31%;
                overflow: hidden;
                background: url("https://iv.vu818.com/img/menu_3.png") no-repeat;
                transition: 0.5s;
            }
            .tlb3 {
                width: 10px;
                height: 10px;
                position: absolute;
                top: 78%;
                left: 30%;
                overflow: hidden;
                background: url("https://iv.vu818.com/img/menu_3.png") no-repeat bottom;
                transition: 0.5s;
            }
            .tlbAn {
                opacity: 0;
            }
        }
    }
    .p4Box1 {
        width: 100%;
        height: 740px;
        overflow: hidden;
        background: url("https://iv.vu818.com/img/wm1.png");
        .p1Box {
            max-width: 1000px;
            height: 544px;
            margin: 0 auto;
            margin-top: 80px;
            color: white;
            .plbLeft {
                width: 384px;
                height: 100%;
                float: left;
                .pl1 {
                    width: 100%;
                    height: 44px;
                    line-height: 44px;
                    font-weight: 900;
                    font-size: 40px;
                    margin-top: 100px;
                }
                .pl2 {
                    width: 440px;
                    height: 54px;
                    font-size: 18px;
                    margin-top: 30px;
                }
                .pl3 {
                    width: 400px;
                    height: 168px;
                    margin-top: 50px;
                    ul {
                        width: 100%;
                        height: 100%;
                        .p3Li {
                            width: 82px;
                            height: 90px;
                            margin-right: 10px;
                            float: left;
                            .p3l1 {
                                width: 100%;
                                height: 38px;
                                img {
                                    width: 38px;
                                    height: 38px;
                                    margin: 0 auto;
                                    display: block;
                                }
                            }
                            .p3l2 {
                                width: 100%;
                                height: 13px;
                                margin-top: 5px;
                                text-align: center;
                            }
                        }
                        .p3Li:nth-child(5) {
                            margin-left: 40px;
                        }
                    }
                }
            }
            .plbRight {
                width: 576px;
                height: 448px;
                float: right;
                position: relative;
                .pr1 {
                    width: 186px;
                    height: 192px;
                    z-index: 2;
                    position: absolute;
                    right: 150px;
                    top: 20px;
                }
                .pr2 {
                    width: 279px;
                    height: 586px;
                    z-index: 1;
                    position: absolute;
                    right: 80px;
                    top: -120px;
                }
            }
        }
    }
    .p4Box2 {
        width: 100%;
        height: 740px;
        overflow: hidden;
        background: url("https://iv.vu818.com/img/wm2.png");
        .p2Box {
            max-width: 1000px;
            height: 592px;
            margin: 0 auto;
            margin-top: 80px;
            .p2bLeft {
                width: 576px;
                height: 100%;
                float: left;
                position: relative;
                .p2Img1 {
                    width: 378px;
                    height: 250px;
                    position: absolute;
                    left: 95px;
                    top: 35px;
                    z-index: 3;
                }
                .p2Img2 {
                    width: 224px;
                    height: 349px;
                    position: absolute;
                    bottom: 92px;
                    right: 135px;
                    z-index: 2;
                }
                .p2Img3 {
                    width: 432px;
                    height: 464px;
                    position: absolute;
                    top: 45px;
                    left: 70px;
                    z-index: 1;
                }
            }
            .p2bRight {
                width: 384px;
                height: 100%;
                float: right;
                color: white;
                .p2r1 {
                    width: 100%;
                    height: 25px;
                    line-height: 25px;
                    font-size: 32px;
                    margin-top: 50px;
                }
                .p2r2 {
                    width: 100%;
                    height: 144px;
                    font-size: 14px;
                    margin-top: 20px;
                }
                .p2r3 {
                    width: 100%;
                    height: 164px;
                    margin-top: 30px;
                    .p3p {
                        width: 100%;
                        height: 20px;
                        line-height: 20px;
                        font-size: 24px;
                    }
                    ul {
                        width: 100%;
                        height: 129px;
                        margin-top: 10px;
                        .p3Li {
                            width: 69px;
                            height: 55px;
                            float: left;
                            margin-top: 5px;
                            margin-left: 5px;
                            .p3l1 {
                                width: 100%;
                                height: 38px;
                                img {
                                    width: 38px;
                                    height: 38px;
                                    display: block;
                                    margin: 0 auto;
                                }
                            }

                            .p3l2 {
                                width: 100%;
                                height: 14px;
                                // line-height: 14px;
                                text-align: center;
                                font-size: 14px;
                            }
                        }
                    }
                }
                .p2r4 {
                    width: 120px;
                    height: 36px;
                    line-height: 36px;
                    text-align: center;
                    font-size: 14px;
                    border-radius: 5px;
                    border: 2px solid white;
                    margin-top: 30px;
                    cursor: pointer;
                }
            }
        }
    }
    .p4Box3 {
        width: 100%;
        height: 740px;
        overflow: hidden;
        background: url("https://iv.vu818.com/img/wm3.png");
        .p3Box {
            max-width: 1000px;
            height: 592px;
            margin: 0 auto;
            margin-top: 80px;
            .p3bLeft {
                width: 384px;
                height: 100%;
                float: left;
                color: white;
                .p3r1 {
                    width: 100%;
                    height: 25px;
                    line-height: 25px;
                    font-size: 32px;
                    margin-top: 50px;
                }
                .p3r2 {
                    width: 100%;
                    height: 144px;
                    font-size: 14px;
                    margin-top: 20px;
                }
                .p3r3 {
                    width: 100%;
                    height: 164px;
                    margin-top: 30px;
                    .p3p {
                        width: 100%;
                        height: 20px;
                        line-height: 20px;
                        font-size: 24px;
                    }
                    ul {
                        width: 100%;
                        height: 129px;
                        margin-top: 10px;
                        .p3Li {
                            width: 69px;
                            height: 55px;
                            float: left;
                            margin-top: 5px;
                            margin-left: 5px;
                            .p3l1 {
                                width: 100%;
                                height: 38px;
                                img {
                                    width: 38px;
                                    height: 38px;
                                    display: block;
                                    margin: 0 auto;
                                }
                            }

                            .p3l2 {
                                width: 100%;
                                height: 14px;
                                line-height: 14px;
                                text-align: center;
                                font-size: 14px;
                            }
                        }
                    }
                }
                .p3r4 {
                    width: 120px;
                    height: 36px;
                    line-height: 36px;
                    text-align: center;
                    font-size: 14px;
                    border-radius: 5px;
                    border: 2px solid white;
                    margin-top: 30px;
                    cursor: pointer;
                }
            }
            .p3bRight {
                width: 576px;
                height: 100%;
                float: right;
                position: relative;
                .p3Img1 {
                    width: 386px;
                    height: 217px;
                    position: absolute;
                    left: 135px;
                    top: 75px;
                    z-index: 3;
                }
                .p3Img2 {
                    width: 103px;
                    height: 98px;
                    position: absolute;
                    bottom: 92px;
                    right: 0px;
                    z-index: 2;
                }
                .p3Img3 {
                    width: 303px;
                    height: 144px;
                    position: absolute;
                    bottom: 90px;
                    left: 60px;
                    z-index: 2;
                }
                .p3Img4 {
                    width: 505px;
                    height: 463px;
                    position: absolute;
                    top: 45px;
                    left: 70px;
                    z-index: 1;
                }
            }
        }
    }
    .p4Box4 {
        width: 100%;
        height: 740px;
        overflow: hidden;
        background: url("https://iv.vu818.com/img/wm4.png");
        .p2Box {
            max-width: 1000px;
            height: 592px;
            margin: 0 auto;
            margin-top: 80px;
            .p2bLeft {
                width: 576px;
                height: 100%;
                float: left;
                position: relative;
                .p2Img1 {
                    width: 413px;
                    height: 183px;
                    position: absolute;
                    left: 80px;
                    top: 35px;
                    z-index: 3;
                }
                .p2Img2 {
                    width: 426px;
                    height: 264px;
                    position: absolute;
                    bottom: 70px;
                    right: 70px;
                    z-index: 2;
                }
                .p2Img3 {
                    width: 432px;
                    height: 460px;
                    position: absolute;
                    top: 45px;
                    left: 70px;
                    z-index: 1;
                }
            }
            .p2bRight {
                width: 384px;
                height: 100%;
                float: right;
                color: white;
                .p2r1 {
                    width: 100%;
                    height: 25px;
                    line-height: 25px;
                    font-size: 32px;
                    margin-top: 50px;
                }
                .p2r2 {
                    width: 100%;
                    height: 144px;
                    font-size: 14px;
                    margin-top: 20px;
                }
                .p2r3 {
                    width: 100%;
                    height: 164px;
                    margin-top: 30px;
                    .p3p {
                        width: 100%;
                        height: 20px;
                        line-height: 20px;
                        font-size: 24px;
                    }
                    ul {
                        width: 100%;
                        height: 129px;
                        margin-top: 10px;
                        .p3Li {
                            width: 69px;
                            height: 55px;
                            float: left;
                            margin-top: 5px;
                            margin-left: 5px;
                            .p3l1 {
                                width: 100%;
                                height: 38px;
                                img {
                                    width: 38px;
                                    height: 38px;
                                    display: block;
                                    margin: 0 auto;
                                }
                            }

                            .p3l2 {
                                width: 100%;
                                height: 14px;
                                line-height: 14px;
                                text-align: center;
                                font-size: 14px;
                            }
                        }
                    }
                }
                .p2r4 {
                    width: 120px;
                    height: 36px;
                    line-height: 36px;
                    text-align: center;
                    font-size: 14px;
                    border-radius: 5px;
                    border: 2px solid white;
                    margin-top: 30px;
                    cursor: pointer;
                }
            }
        }
    }
    .p4Box5 {
        width: 100%;
        height: 740px;
        overflow: hidden;
        background: url("https://iv.vu818.com/img/wm5.png");
        .p3Box {
            max-width: 1000px;
            height: 592px;
            margin: 0 auto;
            margin-top: 80px;
            .p3bLeft {
                width: 384px;
                height: 100%;
                float: left;
                color: #724009;
                .p3r1 {
                    width: 100%;
                    height: 25px;
                    line-height: 25px;
                    font-size: 32px;
                    margin-top: 50px;
                }
                .p3r2 {
                    width: 100%;
                    height: 144px;
                    font-size: 14px;
                    margin-top: 20px;
                }
                .p3r3 {
                    width: 100%;
                    height: 164px;
                    margin-top: 30px;
                    .p3p {
                        width: 100%;
                        height: 20px;
                        line-height: 20px;
                        font-size: 24px;
                    }
                    ul {
                        width: 100%;
                        height: 129px;
                        margin-top: 10px;
                        .p3Li {
                            width: 69px;
                            height: 55px;
                            float: left;
                            margin-top: 5px;
                            margin-left: 5px;
                            .p3l1 {
                                width: 100%;
                                height: 38px;
                                img {
                                    width: 38px;
                                    height: 38px;
                                    display: block;
                                    margin: 0 auto;
                                }
                            }

                            .p3l2 {
                                width: 100%;
                                height: 14px;
                                line-height: 14px;
                                text-align: center;
                                font-size: 14px;
                            }
                        }
                    }
                }
                .p3r4 {
                    width: 120px;
                    height: 36px;
                    line-height: 36px;
                    text-align: center;
                    font-size: 14px;
                    border-radius: 5px;
                    border: 2px solid #724009;
                    margin-top: 30px;
                    cursor: pointer;
                }
            }
            .p3bRight {
                width: 576px;
                height: 100%;
                float: right;
                position: relative;
                .p3Img1 {
                    width: 360px;
                    height: 301px;
                    position: absolute;
                    left: 120px;
                    top: 170px;
                    z-index: 3;
                }
                .p3Img2 {
                    width: 436px;
                    height: 120px;
                    position: absolute;
                    bottom: 70px;
                    right: 60px;
                    z-index: 2;
                }
                .p3Img3 {
                    width: 436px;
                    height: 462px;
                    position: absolute;
                    top: 45px;
                    left: 70px;
                    z-index: 1;
                }
            }
        }
    }
    .p4Box6 {
        width: 100%;
        height: 740px;
        overflow: hidden;
        background: url("https://iv.vu818.com/img/wm6.png");
        .p2Box {
            max-width: 1000px;
            height: 592px;
            margin: 0 auto;
            margin-top: 80px;
            .p2bLeft {
                width: 576px;
                height: 100%;
                float: left;
                position: relative;
                .p2Img1 {
                    width: 464px;
                    height: 188px;
                    position: absolute;
                    left: 65px;
                    top: 40px;
                    z-index: 3;
                }
                .p2Img2 {
                    width: 379px;
                    height: 272px;
                    position: absolute;
                    bottom: 115px;
                    right: 60px;
                    z-index: 2;
                }
                .p2Img3 {
                    width: 464px;
                    height: 432px;
                    position: absolute;
                    top: 45px;
                    left: 70px;
                    z-index: 1;
                }
            }
            .p2bRight {
                width: 384px;
                height: 100%;
                float: right;
                color: white;
                .p2r1 {
                    width: 100%;
                    height: 25px;
                    line-height: 25px;
                    font-size: 32px;
                    margin-top: 50px;
                }
                .p2r2 {
                    width: 100%;
                    height: 144px;
                    font-size: 14px;
                    margin-top: 20px;
                }
                .p2r3 {
                    width: 100%;
                    height: 100px;
                    margin-top: 30px;
                    .p3p {
                        width: 100%;
                        height: 20px;
                        line-height: 20px;
                        font-size: 24px;
                    }
                    ul {
                        width: 100%;
                        height: 64px;
                        margin-top: 10px;
                        .p3Li {
                            width: 84px;
                            height: 55px;
                            float: left;
                            margin-top: 5px;
                            margin-left: 5px;
                            .p3l1 {
                                width: 100%;
                                height: 38px;
                                img {
                                    width: 38px;
                                    height: 38px;
                                    display: block;
                                    margin: 0 auto;
                                }
                            }

                            .p3l2 {
                                width: 100%;
                                height: 14px;
                                line-height: 14px;
                                text-align: center;
                                font-size: 14px;
                            }
                        }
                    }
                }
                .p2r4 {
                    width: 120px;
                    height: 36px;
                    line-height: 36px;
                    text-align: center;
                    font-size: 14px;
                    border-radius: 5px;
                    border: 2px solid white;
                    margin-top: 30px;
                    cursor: pointer;
                }
            }
        }
    }
    .p4Box7 {
        width: 100%;
        height: 740px;
        overflow: hidden;
        background: url("https://iv.vu818.com/img/wm7.png");
        .p3Box {
            max-width: 1000px;
            height: 592px;
            margin: 0 auto;
            margin-top: 80px;
            .p3bLeft {
                width: 384px;
                height: 100%;
                float: left;
                color: white;
                .p3r1 {
                    width: 100%;
                    height: 25px;
                    line-height: 25px;
                    font-size: 32px;
                    margin-top: 50px;
                }
                .p3r2 {
                    width: 100%;
                    height: 72px;
                    font-size: 14px;
                    margin-top: 20px;
                }
                .p3r3 {
                    width: 100%;
                    height: 100px;
                    margin-top: 30px;
                    .p3p {
                        width: 100%;
                        height: 20px;
                        line-height: 20px;
                        font-size: 24px;
                    }
                    ul {
                        width: 100%;
                        height: 64px;
                        margin-top: 10px;
                        .p3Li {
                            width: 80px;
                            height: 55px;
                            float: left;
                            margin-top: 5px;
                            margin-left: 5px;
                            .p3l1 {
                                width: 100%;
                                height: 38px;
                                img {
                                    width: 38px;
                                    height: 38px;
                                    display: block;
                                    margin: 0 auto;
                                }
                            }

                            .p3l2 {
                                width: 100%;
                                height: 14px;
                                line-height: 14px;
                                text-align: center;
                                font-size: 14px;
                            }
                        }
                    }
                }
                .p3r4 {
                    width: 120px;
                    height: 36px;
                    line-height: 36px;
                    text-align: center;
                    font-size: 14px;
                    border-radius: 5px;
                    border: 2px solid white;
                    margin-top: 30px;
                    cursor: pointer;
                }
            }
            .p3bRight {
                width: 576px;
                height: 100%;
                float: right;
                position: relative;
                .p3Img1 {
                    width: 356px;
                    height: 211px;
                    position: absolute;
                    left: 120px;
                    top: 45px;
                    z-index: 3;
                }
                .p3Img2 {
                    width: 160px;
                    height: 304px;
                    position: absolute;
                    bottom: 138px;
                    right: 183px;
                    z-index: 3;
                }
                .p3Img3 {
                    width: 490px;
                    height: 79px;
                    position: absolute;
                    bottom: 95px;
                    left: 70px;
                    z-index: 2;
                }
                .p3Img4 {
                    width: 490px;
                    height: 447px;
                    position: absolute;
                    top: 45px;
                    left: 70px;
                    z-index: 1;
                }
            }
        }
    }
    .p4Box8 {
        width: 100%;
        height: 740px;
        overflow: hidden;
        background: url("https://iv.vu818.com/img/wm8.png");
        .p2Box {
            max-width: 1000px;
            height: 592px;
            margin: 0 auto;
            margin-top: 80px;
            .p2bLeft {
                width: 576px;
                height: 100%;
                float: left;
                position: relative;
                .p2Img1 {
                    width: 412px;
                    height: 284px;
                    position: absolute;
                    left: 80px;
                    top: 137px;
                    z-index: 2;
                }
                .p2Img2 {
                    width: 432px;
                    height: 432px;
                    position: absolute;
                    top: 45px;
                    left: 70px;
                    z-index: 1;
                }
            }
            .p2bRight {
                width: 384px;
                height: 100%;
                float: right;
                color: white;
                .p2r1 {
                    width: 100%;
                    height: 25px;
                    line-height: 25px;
                    font-size: 32px;
                    margin-top: 50px;
                }
                .p2r2 {
                    width: 100%;
                    height: 168px;
                    font-size: 14px;
                    margin-top: 20px;
                }
                .p2r3 {
                    width: 100%;
                    height: 100px;
                    margin-top: 30px;
                    .p3p {
                        width: 100%;
                        height: 20px;
                        line-height: 20px;
                        font-size: 24px;
                    }
                    ul {
                        width: 100%;
                        height: 64px;
                        margin-top: 10px;
                        .p3Li {
                            width: 84px;
                            height: 55px;
                            float: left;
                            margin-top: 5px;
                            margin-left: 5px;
                            .p3l1 {
                                width: 100%;
                                height: 38px;
                                img {
                                    width: 38px;
                                    height: 38px;
                                    display: block;
                                    margin: 0 auto;
                                }
                            }

                            .p3l2 {
                                width: 100%;
                                height: 14px;
                                line-height: 14px;
                                text-align: center;
                                font-size: 14px;
                            }
                        }
                    }
                }
                .p2r4 {
                    width: 120px;
                    height: 36px;
                    line-height: 36px;
                    text-align: center;
                    font-size: 14px;
                    border-radius: 5px;
                    border: 2px solid white;
                    margin-top: 30px;
                    cursor: pointer;
                }
            }
        }
    }
    .p4Box9 {
        width: 100%;
        height: 740px;
        overflow: hidden;
        background: url("https://iv.vu818.com/img/wm9.png");
        .p3Box {
            max-width: 1000px;
            height: 592px;
            margin: 0 auto;
            margin-top: 80px;
            .p3bLeft {
                width: 384px;
                height: 100%;
                float: left;
                color: white;
                .p3r1 {
                    width: 100%;
                    height: 25px;
                    line-height: 25px;
                    font-size: 32px;
                    margin-top: 50px;
                }
                .p3r2 {
                    width: 100%;
                    height: 72px;
                    font-size: 14px;
                    margin-top: 20px;
                }
                .p3r3 {
                    width: 100%;
                    height: 100px;
                    margin-top: 30px;
                    .p3p {
                        width: 100%;
                        height: 20px;
                        line-height: 20px;
                        font-size: 24px;
                    }
                    ul {
                        width: 100%;
                        height: 64px;
                        margin-top: 10px;
                        .p3Li {
                            width: 80px;
                            height: 55px;
                            float: left;
                            margin-top: 5px;
                            margin-left: 5px;
                            .p3l1 {
                                width: 100%;
                                height: 38px;
                                img {
                                    width: 38px;
                                    height: 38px;
                                    display: block;
                                    margin: 0 auto;
                                }
                            }

                            .p3l2 {
                                width: 100%;
                                height: 14px;
                                line-height: 14px;
                                text-align: center;
                                font-size: 14px;
                            }
                        }
                    }
                }
                .p3r4 {
                    width: 120px;
                    height: 36px;
                    line-height: 36px;
                    text-align: center;
                    font-size: 14px;
                    border-radius: 5px;
                    border: 2px solid white;
                    margin-top: 30px;
                    cursor: pointer;
                }
            }
            .p3bRight {
                width: 576px;
                height: 100%;
                float: right;
                position: relative;
                .p3Img1 {
                    width: 352px;
                    height: 210px;
                    position: absolute;
                    left: 120px;
                    top: 45px;
                    z-index: 3;
                }
                .p3Img2 {
                    width: 404px;
                    height: 345px;
                    position: absolute;
                    bottom: 70px;
                    right: 70px;
                    z-index: 3;
                }
                .p3Img3 {
                    width: 432px;
                    height: 442px;
                    position: absolute;
                    position: absolute;
                    top: 45px;
                    left: 70px;
                    z-index: 1;
                }
            }
        }
    }
    .p4Box10 {
        width: 100%;
        height: 740px;
        overflow: hidden;
        background: url("https://iv.vu818.com/img/wm10.png");
        .p2Box {
            max-width: 1000px;
            height: 592px;
            margin: 0 auto;
            margin-top: 80px;
            .p2bLeft {
                width: 576px;
                height: 100%;
                float: left;
                position: relative;
                .p2Img1 {
                    width: 403px;
                    height: 176px;
                    position: absolute;
                    left: 65px;
                    top: 40px;
                    z-index: 3;
                }
                .p2Img2 {
                    width: 331px;
                    height: 308px;
                    position: absolute;
                    bottom: 83px;
                    right: 111px;
                    z-index: 2;
                }
                .p2Img3 {
                    width: 441px;
                    height: 465px;
                    position: absolute;
                    top: 45px;
                    left: 70px;
                    z-index: 1;
                }
            }
            .p2bRight {
                width: 384px;
                height: 100%;
                float: right;
                color: white;
                .p2r1 {
                    width: 100%;
                    height: 25px;
                    line-height: 25px;
                    font-size: 32px;
                    margin-top: 50px;
                }
                .p2r2 {
                    width: 100%;
                    height: 144px;
                    font-size: 14px;
                    margin-top: 20px;
                }
                .p2r3 {
                    width: 100%;
                    height: 100px;
                    margin-top: 30px;
                    .p3p {
                        width: 100%;
                        height: 20px;
                        line-height: 20px;
                        font-size: 24px;
                    }
                    ul {
                        width: 100%;
                        height: 64px;
                        margin-top: 10px;
                        .p3Li {
                            width: 84px;
                            height: 55px;
                            float: left;
                            margin-top: 5px;
                            margin-left: 5px;
                            .p3l1 {
                                width: 100%;
                                height: 38px;
                                img {
                                    width: 38px;
                                    height: 38px;
                                    display: block;
                                    margin: 0 auto;
                                }
                            }

                            .p3l2 {
                                width: 100%;
                                height: 14px;
                                line-height: 14px;
                                text-align: center;
                                font-size: 14px;
                            }
                        }
                    }
                }
                .p2r4 {
                    width: 120px;
                    height: 36px;
                    line-height: 36px;
                    text-align: center;
                    font-size: 14px;
                    border-radius: 5px;
                    border: 2px solid white;
                    margin-top: 30px;
                    cursor: pointer;
                }
            }
        }
    }
    .p4Box11 {
        width: 100%;
        height: 740px;
        overflow: hidden;
        background: url("https://iv.vu818.com/img/wm11.png");
        .p3Box {
            max-width: 1000px;
            height: 592px;
            margin: 0 auto;
            margin-top: 80px;
            .p3bLeft {
                width: 384px;
                height: 100%;
                float: left;
                color: white;
                .p3r1 {
                    width: 100%;
                    height: 25px;
                    line-height: 25px;
                    font-size: 32px;
                    margin-top: 50px;
                }
                .p3r2 {
                    width: 100%;
                    height: 120px;
                    font-size: 14px;
                    margin-top: 20px;
                }
                .p3r3 {
                    width: 100%;
                    height: 100px;
                    margin-top: 30px;
                    .p3p {
                        width: 100%;
                        height: 20px;
                        line-height: 20px;
                        font-size: 24px;
                    }
                    ul {
                        width: 100%;
                        height: 64px;
                        margin-top: 10px;
                        .p3Li {
                            width: 80px;
                            height: 55px;
                            float: left;
                            margin-top: 5px;
                            margin-left: 5px;
                            .p3l1 {
                                width: 100%;
                                height: 38px;
                                overflow: hidden;
                                img {
                                    width: 38px;
                                    height: 38px;
                                    display: block;
                                    margin: 0 auto;
                                }
                            }

                            .p3l2 {
                                width: 100%;
                                height: 14px;
                                line-height: 14px;
                                text-align: center;
                                font-size: 14px;
                            }
                        }
                    }
                }
                .p3r4 {
                    width: 120px;
                    height: 36px;
                    line-height: 36px;
                    text-align: center;
                    font-size: 14px;
                    border-radius: 5px;
                    border: 2px solid white;
                    margin-top: 30px;
                    cursor: pointer;
                }
            }
            .p3bRight {
                width: 576px;
                height: 100%;
                float: right;
                position: relative;
                .p3Img1 {
                    width: 368px;
                    height: 351px;
                    position: absolute;
                    left: 100px;
                    top: 105px;
                    z-index: 3;
                }
                .p3Img2 {
                    width: 393px;
                    height: 377px;
                    position: absolute;
                    bottom: 145px;
                    right: 95px;
                    z-index: 3;
                }
                .p3Img3 {
                    width: 432px;
                    height: 432px;
                    position: absolute;
                    position: absolute;
                    top: 45px;
                    left: 70px;
                    z-index: 1;
                }
            }
        }
    }
}
</style>
